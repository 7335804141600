@import url('https://fonts.googleapis.com/css?family=Quicksand:400,700'); // feel free to change this font type at any momment.
$primary: #ff9933;

/* MAIN COLOR */

$secondary: #9a9c9f;

/* SECONDARY COLOR */

$gray: #666;

/* DARK GRAY, REASON WHY IT'S SET TO #333 IS SO YOU CAN DARKEN IT IF NEEDED USING darken($blk, 10%); FOR EXAMPLE. YOU COULD ALSO USE 2 VARIABLES INSTEAD */

$blk: #333;

/* DARK GRAY, REASON WHY IT'S SET TO #333 IS SO YOU CAN DARKEN IT IF NEEDED USING darken($blk, 10%); FOR EXAMPLE. YOU COULD ALSO USE 2 VARIABLES INSTEAD */

$wht: #fafafa;
$footerLinks: #333;

/* Change autocomplete styles in WebKit */

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus input:-webkit-autofill,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    border: 1px solid #0065dd;
    -webkit-text-fill-color: #0065dd;
    -webkit-box-shadow: 0 0 0px 1000px #000 inset;
    transition: background-color 5000s ease-in-out 0s;
}

body {
    font-size: 1.5em;
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        font-family: 'Palanquin Dark', sans-serif;
    }
}

p,
ol,
ul {
    font-family: 'Rhodium Libre', serif;
}

nav {
    z-index: 10;
}

.navbar-default {
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#cecece+1,ffffff+100 */
    background: rgb(206, 206, 206);
    /* Old browsers */
    background: -moz-linear-gradient(top, rgba(206, 206, 206, 1) 1%, rgba(255, 255, 255, 1) 100%);
    /* FF3.6-15 */
    background: -webkit-linear-gradient(top, rgba(206, 206, 206, 1) 1%, rgba(255, 255, 255, 1) 100%);
    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, rgba(206, 206, 206, 1) 1%, rgba(255, 255, 255, 1) 100%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
}

.navbar-brand {
    padding: 5px;
    margin-top: 5px;
    @media(max-width: 767px) {
        height: 70px; // nav height mobile,(feel free to change this values as you please)
        .logo {
            max-height: 60px; // logo height changed on mobile
        }
    }
}

.navbar .navbar-nav {
    >li>a {
        text-align: center;
        margin-top: 8px;
        display: flex;
        align-items: center;
        color: black;
        transition: 1s all ease;
        @media (max-width: 767px) {
            margin-top: 0;
            display: inline-block;
            height: 25px;
        }
        &:hover {
            background: transparent;
            color: $primary;
            @media (max-width: 1024px) {
                background: transparent;
                color: $blk;
                outline: 0;
            }
        }
        &:focus,
        &:active {
            background: transparent;
            color: $blk;
            outline: 0;
        }
    }
}

.navbar-right {
    margin-top: 5px;
    @media (max-width: 1024px) {
        margin-top: 0px;
        font-size: 1.5em;
    }
}

.navbar-toggle {
    margin: 18px 15px 8px 0px;
    border: 1px solid transparent;
    border-radius: 4px;
}

// here start mixins ---- this is a style for buttons you can add it to your a tag like this (@include btn, look at the .btn-default style), these values can be changed anytime you need to.
@mixin btn {
    text-align: center;
    margin: 0 auto;
    border: 1px solid $primary;
    border-radius: 0px;
    background: $primary;
    color: $wht;
    padding: 0.5em 1em;
    font-size: 1.25em;
    font-weight: bold;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 1px;
    margin: 2em 0em;
    &:hover {
        background: lighten($primary, 10%);
        color: $wht;
        box-shadow: 0px 0px 3px lighten($blk, 20%);
        text-decoration: none;
    }
}

.btn-default {
    @include btn;
}

// mixins end here
// sections start
.section-a {
    padding: 60px;
    margin-top: 100px; // this marging  will depend on the height of the nav, (if you have a bigger or smaller nav feel free to change it)  'if you need a transparent nav and see the img change margin to padding'
}

// sections end
.modal-dialog {
    max-width: 500px;
    width: 100%;
    text-align: center;
    margin: 6em auto;
    .close {
        display: none;
    }
    .modal-content {
        color: $primary;
        h2 {
            text-align: center;
        }
    }
    a.btn,
    a.btn-default,
    button {
        @include btn margin: 0;
        display: block;
    }
}

.modal-header,
.modal-footer {
    background: $primary;
    color: $wht;
}

.modal-open {
    overflow: auto;
    padding-right: 0px !important;
}

footer {
    padding: 50px 0px 20px;
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#cecece+1,ffffff+100 */
    background: rgb(206, 206, 206);
    /* Old browsers */
    background: -moz-linear-gradient(top, rgba(206, 206, 206, 1) 1%, rgba(255, 255, 255, 1) 100%);
    /* FF3.6-15 */
    background: -webkit-linear-gradient(top, rgba(206, 206, 206, 1) 1%, rgba(255, 255, 255, 1) 100%);
    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, rgba(206, 206, 206, 1) 1%, rgba(255, 255, 255, 1) 100%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    color: $footerLinks;
    font-size: 15px;
    a {
        color: $footerLinks;
        white-space: nowrap;
        font-size: 15px;
        &:hover {
            color: lighten($footerLinks, 10%);
            outline: 0 !important;
            text-decoration: none;
        }
        &:focus {
            color: $footerLinks;
            outline: 0 !important;
            text-decoration: none;
        }
    }
    p {
        font-size: 15px;
        @media (max-width: 990px) {
            font-size: 13px;
        }
    }
}

.logo {
    max-width: 225px;
    padding: 0.5em;
}

@media(max-width: 1024px) {
    .logo {
        max-width: 175px;
    }
}

@media(max-width: 767px) {
    .logo {
        max-width: 150px;
    }
    .navbar-header a {
        float: left;
    }
}


/* ============ HEADER ============= */

.header {
    background: url('../img/banner.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 50%;
    margin-top: 65px;
    @media (max-width: 767px) {
        margin-top: 60px;
    }
}

.header-overlay {
    background: rgba(31, 31, 31, .5);
    padding: 350px 0px;
    @media (max-width: 767px) {
        padding: 75px 0px;
    }
}

.header h1 {
    color: $wht;
    font-size: 4em;
}

.header p {
    color: $wht;
    font-size: 1.15em;
    letter-spacing: 1px;
    line-height: 30px;
}


/* ========== UPPER CTA ========== */

.upper-cta {
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#cecece+1,ffffff+100 */
    background: rgb(206, 206, 206);
    /* Old browsers */
    background: -moz-linear-gradient(top, rgba(206, 206, 206, 1) 1%, rgba(255, 255, 255, 1) 100%);
    /* FF3.6-15 */
    background: -webkit-linear-gradient(top, rgba(206, 206, 206, 1) 1%, rgba(255, 255, 255, 1) 100%);
    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, rgba(206, 206, 206, 1) 1%, rgba(255, 255, 255, 1) 100%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    border-bottom: 1px solid $secondary;
}

.upper-cta-overlay {
    background: rgba(31, 31, 31, .15);
    padding: 15px 0px;
}

.upper-cta h1 {
    color: $primary;
    font-weight: bold;
    margin: 0px;
    text-shadow: 2px 2px $wht;
}


/* ========== SECTION ONE ============= */

.section-one {
    padding: 50px 0px;
}

.section-one h2 {
    color: $primary;
    font-size: 2em;
}

.section-one p {
    color: $blk;
    font-size: 1.15em;
}


/* ========== SECTION TWO ============= */

.section-two {
    background: url('../img/lower-banner.jpg');
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: cover;
    border-top: 1px solid $blk;
    border-bottom: 1px solid $blk;
}

.section-two-overlay {
    background: rgba(31, 31, 31, .25);
    padding: 75px 0px;
}

.section-two h1 {
    color: $wht;
    text-shadow: 2px 2px $primary;
    margin: 0px;
}

.section-two p {
    color: $blk;
    margin-top: 15px;
}


/* ========== SECTION THREE ============= */

.section-three {
    padding: 25px 0px;
}

.section-three h2 {
    color: $primary;
    font-size: 2em;
}

.section-three p {
    color: $blk;
    font-size: 1.15em;
}


/* ========== SECTION FOUR ============= */

.section-four {
    background: url('../img/lower-banner-2.jpg');
    background-repeat: no-repeat;
    background-position: 50% 10%;
    background-size: cover;
    border-top: 1px solid $blk;
    border-bottom: 1px solid $blk;
}

.section-four-overlay {
    background: rgba(31, 31, 31, .25);
    padding: 75px 0px;
}

.section-four h1 {
    color: $wht;
    text-shadow: 2px 2px $primary;
    margin: 0px;
}

.section-four p {
    color: $blk;
    margin-top: 15px;
}


/* ============ SECTION FIVE =========== */

.section-five {
    padding: 25px 0px;
}

.section-five h1 {
    color: $primary;
    font-size: 3em;
}

.section-five p {
    color: $blk;
    font-size: 1.15em;
}


/* ========= BUTTONS ============ */

.btn-download,
.btn-download:focus,
.btn-download:active,
.btn-download:visited {
    background-color: $primary;
    border-color: $wht;
    color: $wht;
    padding: 10px 20px;
    font-size: 19px;
    text-transform: uppercase;
    border-radius: 5px;
    transition: all 1s;
    -webkit-transition: all 1s;
    -moz-transition: all 1s;
    -o-transition: all 1s;
}

.btn-download:hover {
    background-color: $secondary;
    color: $primary;
}

.btn-download {
    margin-bottom: 15px !important;
    display: block;
    // margin: 0 auto;
    max-width: 175px;
    margin-top: 15px;
}


/* ========= BUTTONS ============ */

.btn-download-2,
.btn-download-2:focus,
.btn-download-2:active,
.btn-download-2:visited {
    background-color: $primary;
    border-color: $wht;
    color: $wht;
    padding: 10px 20px;
    font-size: 19px;
    text-transform: uppercase;
    border-radius: 5px;
    transition: all 1s;
    -webkit-transition: all 1s;
    -moz-transition: all 1s;
    -o-transition: all 1s;
}

.btn-download-2:hover {
    background-color: $secondary;
    color: $primary;
}

.btn-download-2 {
    margin-bottom: 15px !important;
    display: block;
    margin: 0 auto;
    max-width: 175px;
    margin-top: 15px;
}